import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { IoMdClose } from "react-icons/io";

import { useState } from "react";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import { CallToAction, CustomerQuote } from "../components/site";

import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import HireScreenshot from "../images/droppah/Hire_Scsreenshot.png";
import PlayLeft from "../images/graphics/rebrand/Play_Left.svg";
import PlayRight from "../images/graphics/rebrand/Play_Right.svg";
import Goals from "../images/graphics/rebrand/Goals.svg";
import Time from "../images/graphics/rebrand/Time.svg";
import Balance from "../images/graphics/rebrand/Balance.svg";
import Juggle from "../images/graphics/rebrand/Juggle.svg";
import Upskill from "../images/graphics/rebrand/Upskill.svg";
import Steps from "../images/graphics/rebrand/Steps.svg";
import Hospo from "../images/graphics/rebrand/Hospo.svg";

import HireQrCode from "../images/droppah/hire_qr_code.svg";


import Logo1154 from "../images/customers/1154.svg";
import DiceAndSliceLogo from "../images/customers/Dice_&_slice.svg";
import ManOWarLogo from "../images/customers/Man_o_war.svg";
import MeanDosesLogo from "../images/customers/Mean_Doses.svg";
import OikosLogo from "../images/customers/Oikos.svg";
import SublimeLogo from "../images/customers/Sublime.svg";


const HeroContainer = styled(Container)`
  .wrapper {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    h1 {
      @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
        padding: 0 100px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0;
      }
    }
  }

  > img {
    position: absolute;
    width: 22%;
    top: 50%;
    transform: translateY(-50%);

    &.-left {
      left: -60px;

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        top: 200px;
      }
    }

    &.-right {
      right: -60px;
      
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        top: 200px;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 140px;
      top: unset;
      transform: translateY(0);
      bottom: 90px;
    }
  }
`;

const SlideContent = styled(Box)`
  padding: 32px;
  text-align: left;

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &.-cherry {
    background-color: ${(props) => props.theme.colours.cherry};
    color: ${(props) => props.theme.colours.honey};
  }

  &.-honey {
    background-color: ${(props) => props.theme.colours.honey};
    color: ${(props) => props.theme.colours.blueberry};
  }

  &.-deeppurple {
    background-color: ${(props) => props.theme.colours.deeppurple};
    color: ${(props) => props.theme.colours.honey};
  }
`;

const Losenge = styled.div`
  position: absolute;
  top: 50%;
  width: 90vw;
  height: 585px;

  &.-orange {
    background-color: ${(props) => props.theme.colours.orange};
  }

  &.-rose {
    background-color: ${(props) => props.theme.colours.rose};
  }

  &.-deeppurple {
    background-color: ${(props) => props.theme.colours.deeppurple};
  }

  &.-emerald {
    background-color: ${(props) => props.theme.colours.emerald};
  }

  &.-cherry {
    background-color: ${(props) => props.theme.colours.cherry};
  }

  &.-ltr {
    left: -50px;
    transform: rotate(3deg) translateY(-50%);
    border-radius: 0 1000px 1000px 0;
  }

  &.-rtl {
    right: -50px;
    transform: rotate(-3deg) translateY(-50%);
    border-radius: 1000px 0 0 1000px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100vw;
    height: 615px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    height: 500px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    transform: rotate(0) translateY(0%) !important;
    top: 0;
    left: 0 !important;
    right: 0 !important;
    width: 100vw !important;
    border-radius: 0 !important;
    height: 100%;
  }
`;

const HireSection = styled(Container)`

  background-color: #483680;
  display: flex;
  justify-content: center;
  .hire-section-container{
    display: flex;
    max-width: 1300px;
    position: relative;
    padding: 60px 40px;
  }
  iframe{
    border: 5px solid #202020;
    margin-right: 70px;
    height: 300px;
    width: 600px;
    max-width: calc(100% - 40px);
    border-radius: 15px;
  }
  h1{
    margin-top: 40px;
    font-size: 28px;
  }

  .hire-section-button-link{
    cursor: pointer;
    margin-top: 10px;
    text-decoration: underline;
  }

  .hire-section-illustration{
    width: 230px;
    position: absolute;
    bottom: 0px;
    right: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    .hire-section-container{
      flex-direction: column;
      text-align: center;
    }

    iframe{
      margin-right: 0px;
      width: 100%;
    }
    .hire-section-iframe-container, .hire-section-button-container{
      display: flex;
      justify-content: center;
    }
    .hire-section-illustration{
      display: none;
    }
  }
`

const HireLosenge = styled(Container)`
  display: flex;
  margin-top: -50px;
  justify-content: end;
  overflow: hidden;
  padding-top: 120px;

  .losenge-container{
    height: 350px;
    width: 80%;
    background-color: ${(props) => props.theme.colours.lilac};
    border-radius: 1000px 0px 0px 1000px;
    display: flex;
    align-items: center;
  }
  img{
    width: 300px;
    margin-bottom: 120px;
    margin-left: -20px;
  }

  .hire-losenge-right{
    flex-grow: 1;
    max-width: 830px;
    color: black;
    margin-right: 80px;
    text-align: right;
    display: flex;
    justify-content: end;
    img{
      width: 120px;
      height: 120px;
      object-fit: cover;
      margin: 20px;

      @media (max-width: 1100px) {
        display: none;
      }
    }
    p{
      margin-top: 20px;
      font-size: 1.7rem;
    }
    h2{
      font-size: 3rem;
    }
    .hire-losenge-right-button-container{
      display: flex;
      align-items: center;
      justify-content: end;
      div{
        display: flex;
        align-items: end;
        flex-direction: column;
      }
      .hire-losenge-right-button-link{
        margin: 0px -15px;
        cursor: pointer;
        padding: 15px;
        color: black;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    img{
      display: none;
    }
    .losenge-container{
      padding: 40px 0px;
      height: auto;
      width: 100%;
      border-radius: 0px;
      display: block;
    }
    .hire-losenge-right{
      margin-right: 0px;
      text-align: center;
      p{
        padding: 0px 20px;
      }
      .hire-losenge-right-button-container{
        div{
          align-items: center;
        }
        justify-content: center;
      }
    }
  }

`

const LosengeContent = styled(Box)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left !important;
  }
`;


const DownloadModal = styled(Container)`
  z-index: 100;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  .download-modal-illustration{
    display: flex !important;

  }
  .download-modal-qr{
    img{
      background-color: black;
      padding: 20px;
      border-radius: 10px;
    }
    display: none !important;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    .download-modal-illustration{
      display: none !important;
    }
    .download-modal-qr{
      display: flex !important;
    }
  }
  
  .download-modal-bg{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-color: black;
    opacity: 0.5;
  }
  .download-modal-inner{
    position: relative;
    z-index: 100;
    width: 500px;
    max-width: calc(100vw - 40px);
    background-color: #202020;
    border-radius: 15px;
    padding: 40px;
    text-align: center;
    .download-modal-close-button{
      cursor: pointer;
      position: absolute;
      top: 10px;
      font-size: 45px;
      right: 10px;
      padding: 10px;
    }
    p{
      margin: 20px 0px;
    }
    div{
      display: flex;
      justify-content: center;
      img{
        min-height: 100px;
      }
    }
  }
`

const LogoBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 40px;
  top: 10px;
  margin-bottom: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
`

const LogoImage = styled.img`
  position: relative;
  height: 60px;
  width: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 90px;
  }
`;

const Benefits = [
  {
    benefit: "Clock in, in less time",
    description:
      "Use the photo time clock to stay on top of breaks & shifts. Bartender, barista and sous chef - all clocked in and out with a selfie.",
    illustration: Time,
    image: "Droppah_Preview_Timeclock.png",
    losenge_colour: "-orange",
  },
  {
    benefit: "Sheet, is that the time?",
    description:
      "Keep a tab on where everyone is and what they’re doing thanks to Droppah’s snazzy live view. ",
    illustration: Juggle,
    image: "Droppah_Live_Break.png",
    losenge_colour: "-rose",
  },
  {
    benefit: "Refine the recipe",
    description:
      "The perfect roster formula isn’t made overnight - add, edit & swap shifts to find the ripe balance for your team.",
    illustration: Upskill,
    image: "Droppah_Calendar_DragNDrop.png",
    losenge_colour: "-deeppurple",
  },
  {
    benefit: "Comms for a cluey crew",
    description:
      "Get all the cooks in the kitchen with simplified roster sharing & shift swapping. Only notify who needs to be in the know.",
    illustration: Goals,
    image: "Droppah_Calendar_Publish.png",
    losenge_colour: "-emerald",
  },
  {
    benefit: "Save your moolah",
    description:
      "Build a budget-friendly roster, then report on it to find the right coverage. No one wants four servers standing around polishing cutlery.",
    illustration: Balance,
    image: "Droppah_Calendar_Coverage.png",
    losenge_colour: "-cherry",
  },
];

const Home = (props) => {
  let [modalOpen, setModalOpen] = useState();

  return (
    <Layout>
      <Seo
        title="Droppah | Rosters, Time Tracking & Attendance Software"
        description="Suss your schedule sitch in a sec with Droppah, staff scheduling, time tracking & attendance software for your business."
        pathname={props.location.pathname}
      />


      <HeroContainer>
        <img className="-left" src={PlayLeft} />
        <img className="-right" src={PlayRight} />
        <Wrapper className="wrapper" stackGap={100}>
          <Box stackGap={60}>
            <Box stackGap={30}>
              <h1
                className="-textCenter -fontLarge -center"
                css={{ maxWidth: "1000px" }}
              >
                Suss your schedule sitch in a sec
              </h1>
              <FlexButtons justify="center">
                <Button
                  className="-xl gtm-cta secondary"
                  to="/interactive-demo"
                >
                  Take A Peek
                </Button>
                <Button className="-xl gtm-cta primary" to="/signup">
                  Sign Me Up!
                </Button>
              </FlexButtons>
            </Box>
            <LogoBox>
              <LogoImage src={MeanDosesLogo}
                alt="Mean Doses">
              </LogoImage>
              <LogoImage src={Logo1154}
                alt="1154">
              </LogoImage>
              <LogoImage src={ManOWarLogo}
                alt="Man O War">
              </LogoImage>
              <LogoImage src={DiceAndSliceLogo}
                alt="Dice & Slice">
              </LogoImage>
              <LogoImage src={OikosLogo}
                alt="Oikos">
              </LogoImage>
              <LogoImage src={SublimeLogo}
                alt="Sublime">
              </LogoImage>
            </LogoBox>
          </Box>
        </Wrapper>
      </HeroContainer>
      {/* <HireLosenge>
        <div className="losenge-container">
          <img src={HireScreenshot} />
          <div className="hire-losenge-right">
            <div>
              <h2>Hire gig workers</h2>
              <p>Finding yourself short-staffed? Access a pool of skilled hospo rockstars to work one-off shifts!</p>
              <div className="hire-losenge-right-button-container">
                <div>
                  <Button className="-md primary" to="/signup">
                    Sign Me Up!
                  </Button>

                  <div role="button" className="hire-losenge-right-button-link" onClick={() => { setModalOpen(!modalOpen) }}>
                    Looking for shifts?
                  </div>
                </div>

              </div>
            </div>
            <img src={Hospo} />

          </div>
        </div>

      </HireLosenge> */}

      {/* <HireSection>
        <div className="hire-section-container">
          <div className="hire-section-iframe-container">
            <iframe src="https://www.youtube.com/embed/3NP7sofEQOk?si=fB-rPuOicGiY4YpM&autoplay=1&color=white&rel=0"
              title="Welcome to Droppah Hire"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen>
            </iframe>
          </div>


          <div>
            <h1>Hire gig workers</h1>
            <p>Finding yourself short-staffed? Access a pool of skilled hospo rockstars to work one-off shifts!</p>
            <div className="hire-section-button-container">
              <Button className="-md primary" to="/signup">
                Sign Me Up!
              </Button>
            </div>


            <div role="button" className="hire-section-button-link" onClick={() => { setModalOpen(!modalOpen) }}>
              Looking for shifts?
            </div>
          </div>
          <img src={Hospo} className="hire-section-illustration" />

        </div>

      </HireSection> */}
      <Container>
        <Wrapper stackGap={60}>
          <CustomerQuote
            quote="Rostering used to be a laborious process. Now with Droppah, we can change the roster in real-time and the sign-in process is instant."
            name="Kieran"
            company="1154 Pastaria"
            pic="1154_Logo_White.png"
            center
            large
          />
        </Wrapper>
      </Container>
      {Benefits.map((item, i) => {
        return (
          <Container
            key={i}
            css={{ backgroundColor: "transparent", overflow: "visible" }}
          >
            <Losenge
              className={`${item.losenge_colour} ${i % 2 !== 0 ? "-rtl" : "-ltr"
                }`}
            />
            <Wrapper stackGap={125}>
              <Row
                alignCenter
                stackGap={60}
                flipRow={i % 2 !== 0 ? true : false}
              >
                <LosengeContent
                  size={55}
                  className={i % 2 !== 0 ? "-textRight" : "-textLeft"}
                >
                  <Row stackGap={30}>
                    <img src={item.illustration} css={{ width: "90px" }} />
                    <Box>
                      <h2>{item.benefit}</h2>
                      <h4>{item.description}</h4>
                    </Box>
                  </Row>
                </LosengeContent>
                <Box size={45}>
                  <Image
                    filename={item.image}
                    alt="Droppah"
                    maxWidth={850}
                    centerImage
                    addShadow
                  />
                </Box>
              </Row>
            </Wrapper>
          </Container>
        );
      })}
      <Container>
        <Wrapper>
          <CustomerQuote
            quote="With Droppah, rostering takes two quick steps at the end of the week and we can see exactly where our money is being spent."
            name="Lea Winzer"
            company="Man O' War"
            pic="ManOWar_Logo_White.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <CallToAction />
      {modalOpen && (
        <DownloadModal>
          <div className="download-modal-bg" onClick={() => { setModalOpen(!modalOpen) }}></div>
          <div className="download-modal-inner">
            <IoMdClose className="download-modal-close-button" onClick={() => { setModalOpen(!modalOpen) }} />
            <div className="download-modal-qr">
              <img src={HireQrCode} css={{ width: "200px" }} />
            </div>
            <div className="download-modal-illustration">
              <img src={Steps} css={{ width: "200px" }} />
            </div>
            <p>Good news! You can get started now. Download the Droppah app, tell us a few details and we’ll notify you as soon as shifts are available in your area. </p>
            <div>
              <a href="https://onelink.to/fxcyqr?utm_source=Droppah&utm_medium=website&utm_campaign=Droppah-Hire-P1&utm_content=HP-slice-button1" target="_blank">
                <Button className="-md primary">
                  Download Droppah
                </Button>
              </a>

            </div>
          </div>
        </DownloadModal>
      )}

    </Layout>

  );
};

export default Home;
